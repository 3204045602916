* {
	box-sizing: border-box;
}
body {
	font-family: "Poppins", sans;
}
b {
	font-weight: bold;
}

.navbar {
	width: 100%;
	height: 70px;
	background: #fff;
	box-shadow: 0px 2.98256px 7.4564px rgba(0, 0, 0, 0.1);
	display: flex;
	align-items: center;
	padding: 0 2rem;
}
.navbar__logo {
	width: 82px;
}
section {
	padding: 36px;
}
.hero {
	display: flex;
	flex-flow: column;
	max-width: 800px;
	align-self: center;
	margin: auto;
}
.hero__img {
	width: 100%;
	max-width: 500px;
	align-self: center;
}
.hero__header {
	font-size: 36px;
	font-weight: 600;
	margin-top: 2rem;
}
.hero__text {
	font-size: 16px;
	font-weight: 300;
	margin-top: 1.5rem;
	width: 320px;
}
.cardContainer {
	padding: 2rem;
	margin-bottom: 1rem;
	display: flex;
	gap: 20px;
	overflow-x: auto;
	margin: auto;
	max-width: 800px;
}
.card {
	width: 180px;
	display: flex;
	flex-flow: column;
	font-size: 12px;
	position: relative;
	flex: 0 0 auto;
}
.card__img {
	border-radius: 9px;
}
.card__status {
	position: absolute;
	background: #fff;
	margin-top: 10px;
	padding: .5rem;
	border-radius: 2px;
	margin: .5rem;
}
.card__details {
	margin-top: 1rem;
	display: flex;
	flex-flow: column;
	gap: .6rem;
}
.card__stats {
	display: flex;
	align-items: center;
	gap: 4px;
}
.grey {
	color: #aaa;
}
.fa-star {
	color: #FE395C;
}
.card__title {
	text-align: center;
}